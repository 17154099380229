import img1 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 115849.png'
import img2 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120045.png'
import img3 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120107.png'
import img4 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120124.png'
import img5 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120138.png'
import img6 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120150.png'
import img7 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120204.png'
import img8 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120215.png'
import img9 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120225.png'
import img10 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120240.png'
import img11 from '../../../img/manualRD220221/Captura de pantalla 2024-10-07 120306.png'


export const dataManual = [

    {

        url: img1,
        name : ''
    },
    {

        url: img2,
        name : ''
    },
    {

        url: img3,
        name : ''
    },
    {

        url: img4,
        name : ''
    },
    {

        url: img5,
        name : ''
    },
    {

        url: img6,
        name : ''
    },
    {

        url: img7,
        name : ''
    },
    {

        url: img8,
        name : ''
    },
    {

        url: img9,
        name : ''
    },
    {

        url: img10,
        name : ''
    },
    {

        url: img11,
        name : ''
    }
]