// import React from 'react';
// import PageMenu from "../../blocks/PageMenu/PageMenu";
// import logo from "../../../img/logoBlanco.png";

// import { registerLicense } from '@syncfusion/ej2-base';
// import './pdfViewer.scss'
// import {
//     PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
//     ThumbnailView, Print, TextSelection, Annotation, TextSearch, FormFields, FormDesigner, Inject
// } from '@syncfusion/ej2-react-pdfviewer';

// interface PdfViewerProps {
//     documentUrl: string;
// }
// const sync = process.env.REACT_APP_SYNCFUSION_LICENSE
//  registerLicense(sync);
//  console.log('sync',sync)
// const PdfViewer: React.FC<PdfViewerProps> = ({ }) => {

//     const documentUrl = `${process.env.REACT_APP_URL_BASE_WEB}/manual/document.pdf`;
//     return (
//         <>

//             <header className={`header-block`}>

//                 <div className="container">
//                     <div className="row">
//                         <div className="col">
//                             <div className="header-content">
//                                 <div className="logo-block">
//                                     <div className="logo-container">
//                                         <img src={logo} className="logo-landingpage" alt="solimat" />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </header>
//             <PageMenu title="Manual Auditoria RD2/2021">

//             </PageMenu>
//             <div className='container container-manual-pdf-viewer'>
//                 <div className='card-manual-rd22221'>

//                     <p>
//                         Hemos preparado un manual completo para ayudarles a completar el formulario correspondiente al Real Decreto 2/2021. Este documento es una herramienta útil que proporciona información detallada y orientaciones prácticas.
//                     </p>
//                     <p className='jump'>
//                         Les recomendamos revisar el manual cuidadosamente antes de proceder con el formulario. Si tienen alguna pregunta o necesitan asistencia adicional, no duden en contactarnos.
//                     </p>
//                     <p>
//                         ¡Gracias por su atención y esperamos que esta guía les sea de gran ayuda!
//                     </p>
//                     <div className="container-btn-download-rd-form">
//                         <div className="btn-box btn-accent ">
//                             <a
//                                 href={documentUrl}
//                                 rel={'Manual-RD2/2021'}
//                                 target="_blank"
//                                 className="link-bold btn-download"
//                             >
//                                 Abrir Manual         </a>

//                         </div>
//                     </div>

//                 </div>


//                 <div style={{ height: '100Vh', width: '100%' }}>
//                     <PdfViewerComponent
//                         id="pdfViewer"
//                         documentPath={documentUrl}
//                         resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
//                         style={{ height: '100vh', width: '100%' }}

//                     >
//                         <Inject services={[Toolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView,
//                             ThumbnailView, Print, TextSelection, TextSearch, FormFields, FormDesigner]} />
//                     </PdfViewerComponent>

//                 </div>
//             </div>

//         </>
//     );
// };

// export default PdfViewer;




import React from 'react'
// import { dataInicio, dataMisCitas, dataHistorial, dataMisGestiones, dataMisPrestaciones, dataMisNotificaciones } from './dataAyuda'
import { useHistory, useLocation } from 'react-router-dom';
import PageMenu from '../../blocks/PageMenu/PageMenu';
import { dataManual } from './dataManual'
import logo from "../../../img/logoBlanco.png";
import './pdfViewer.scss'
const PdfViewer = () => {

    return (
        <>
            <header className={`header-block`}>

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="header-content">
                                <div className="logo-block">
                                    <div className="logo-container">
                                        <img src={logo} className="logo-landingpage" alt="solimat" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <PageMenu title="Manual Auditoría RD 2/2021">

            </PageMenu>

            <div className='container container-manual-pdf-viewer'>
                <div className='card-manual-rd22221'>

                    <p>
                        Hemos preparado un manual completo para ayudarles a completar el formulario correspondiente al Real Decreto 2/2021. Este documento es una herramienta útil que proporciona información detallada y orientaciones prácticas.
                    </p>
                    <p className='jump'>
                        Les recomendamos revisar el manual cuidadosamente antes de proceder con el formulario. Si tienen alguna pregunta o necesitan asistencia adicional, no duden en contactarnos.                     </p>
                    <p>
                        ¡Gracias por su atención y esperamos que esta guía les sea de gran ayuda!
                    </p>


                </div>
            </div>
            <div className="container container-carrusel-manual mt4">
                <div id="carouselManualGeneral" className="carousel carousel-dark carousel slide carousel-fade" data-bs-touch="false">
                    <div className="carousel-indicators">
                        {Object.keys(dataManual).map((path, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselManualGeneral"
                                data-bs-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                            />
                        ))}
                    </div>
                    <div className="carousel-inner bg-dark">

                        {dataManual?.map((help, i) => (
                            <div className={`carousel-item  ${i === 0 ? 'active manual' : ''} d-flex justify-content-center align-items-center mt-4 mb-4`} key={i}>
                                <img src={help.url} className='img-fluid img-carrusel-manual' alt={help.alt} />
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev prev" type="button" data-bs-target="#carouselManualGeneral" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next next" type="button" data-bs-target="#carouselManualGeneral" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

        </>
    )
}

export default PdfViewer






