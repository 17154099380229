import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import JSZip from 'jszip';
import PageMenu from "../../blocks/PageMenu/PageMenu";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import IconCheck from "../../../img/icons/symbols/check-confirm.png";
import useGetRoyalDecreeFormById from 'hooks/useGetRoyalDecreeFormById';
import useUpdateIncompatibilityDocuments from 'hooks/useUpdateIncompatibilityDocuments';
import moment from "moment";
import useDecode from "hooks/useDecode";
import useCheckRecordStatusAndTypes from 'hooks/useCheckRecordStatusAndTypes';
import Delete from "../../../img/icons/svg/botones/delete.png";
import IMGnotice from "../../../img/icons/symbols/notice.png";
import './formRoyalDecree22021.scss';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Modal, Button } from 'react-bootstrap';
import Loader from 'components/blocks/Loader/Loader';
const FormRoyalDecree22021: React.FC = (props: any) => {
    const { getRoyalDecreeFormById, loadingGetRoyalDecreeFormById, royalDecreeFormById, successGetRoyalDecreeFormById } = useGetRoyalDecreeFormById();
    const { UpdateIncompatibilityDocuments, loadingUpdateIncompatibilityDocuments, successUpdateIncompatibilityDocuments, errorUpdateIncompatibilityDocuments } = useUpdateIncompatibilityDocuments();
    const tokenResponse = useDecode("token");
    const userId = tokenResponse && tokenResponse.extension_id;
    const params = props.match.params;
    const paramRecordId = params.id.toString().split("_");
    const prestacionId = paramRecordId.length > 0 ? paramRecordId.length > 1 ? paramRecordId.at(1) : paramRecordId.at(0) : params.id;
    const formId = params.idform;
    const { checkFormStatusRD, checkFormStatusDocumentsRD } = useCheckRecordStatusAndTypes();
    const history = useHistory();
    const { control, handleSubmit, setValue, watch } = useForm();
    const [showModal, setShowModal] = useState(false);
    const [showModalSuccessfully, setShowModalSuccessfully] = useState(false);
    const [currentObservations, setCurrentObservations] = useState('');
    const noData = 'Sin datos'
    const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: { files: File[]; incompatibilityId: string; incompatibilityName: string; documentName: string } }>({});

    useEffect(() => {
        getRoyalDecreeFormById(userId, prestacionId, formId);
        setSelectedFiles({})
    }, [userId, prestacionId, formId, successUpdateIncompatibilityDocuments]);

    useEffect(() => {

        if (successUpdateIncompatibilityDocuments) {
            handleShowModalsuccessfully()
        }
    }, [successGetRoyalDecreeFormById, successUpdateIncompatibilityDocuments]);
    const handleFileChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        documentId: string,
        incompatibilityId: string,
        incompatibilityName: string,
        documentName: string
    ) => {
        const newFiles = Array.from(e.target.files as FileList);

        if (newFiles.length > 0) {
            setSelectedFiles(prevSelectedFiles => {

                const existingFiles = prevSelectedFiles[documentId]?.files || [];

                return {
                    ...prevSelectedFiles,
                    [documentId]: {
                        files: [...existingFiles, ...newFiles],
                        incompatibilityId,
                        incompatibilityName,
                        documentName,
                    },
                };
            });
        }
    };

    const removeFile = (documentId: string, fileIndex: number) => {
        setSelectedFiles(prevSelectedFiles => {
            const updatedFiles = prevSelectedFiles[documentId]?.files.filter((_, index) => index !== fileIndex);

            if (updatedFiles.length === 0) {
                const { [documentId]: removed, ...rest } = prevSelectedFiles;
                return rest;
            }

            return {
                ...prevSelectedFiles,
                [documentId]: {
                    ...prevSelectedFiles[documentId],
                    files: updatedFiles
                }
            };
        });
    };
    const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const result = reader.result as string;
                const base64Data = result.split(',')[1];
                resolve(base64Data);
            };
            reader.onerror = error => reject(error);
        });
    };

    const createZipFile = async (files: File[]): Promise<string> => {
        const zip = new JSZip();
        for (const file of files) {
            const base64 = await convertFileToBase64(file);
            zip.file(file.name, base64.split(',')[1], { base64: true });
        }
        const zipContent = await zip.generateAsync({ type: "base64" });
        return zipContent;
    };

    const onSubmit = async () => {
        const incompatibilitiesMap = new Map();

        for (const documentId in selectedFiles) {
            const { files, incompatibilityId, documentName } = selectedFiles[documentId];

            let zipContent: string | null = null;


            if (files.length > 1) {
                zipContent = await createZipFile(files);
                // console.log('files', files)
                // console.log('zipContent', zipContent)
            } else if (files.length === 1) {

                zipContent = await convertFileToBase64(files[0]);

            }
            if (zipContent) {
                if (!incompatibilitiesMap.has(incompatibilityId)) {
                    incompatibilitiesMap.set(incompatibilityId, {
                        id: incompatibilityId,
                        documentos: []
                    });
                }

                incompatibilitiesMap.get(incompatibilityId).documentos.push({
                    id: documentId,
                    nombre: `${documentName}`,
                    extension: files.length > 1 ? 'zip' : files[0].name.split('.').pop(),
                    base64: zipContent
                });
            }
        }

        const incompatibilidades = Array.from(incompatibilitiesMap.values());

        const finalObject = {
            id: royalDecreeFormById?.Id,
            incompatibilidades: incompatibilidades
        };

        await UpdateIncompatibilityDocuments(userId, prestacionId, formId, finalObject);
    };

    const hasSelectedFiles = Object.keys(selectedFiles).length > 0;

    const handleShowModal = (observations: string) => {
        setCurrentObservations(observations);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentObservations('');
    };
    const handleShowModalsuccessfully = () => {

        setShowModalSuccessfully(true);
    };

    const handleCloseModalsuccessfully = () => {
        setShowModalSuccessfully(false);
        setCurrentObservations('');
    };
    const handlePrevetAttach = (estate: number) => {
        switch (estate) {
            case 0:
                return false; // "Pendiente Adjuntar Documentos"
            case 1:
                return false; //"Pendiente Validar Gestor"
            case 2:
                return true; //"Aceptado"
            case 3:
                return true; //"Aceptado sin Requerir Documento"
            case 4:
                return false; //"Rechazado"
            case 5:
                return true; //"Obsoleto"
            default:
                return true; //"Estado Desconocido"
        }
    }
    // const prueba = {
    //     "Id": "01eeef94-436b-4b38-03ce-08dcc10aa92e",
    //     "ControlId": 102,
    //     "PersonaId": 80677,
    //     "ExpedienteId": 1345543,
    //     "UsuarioId": "d231f626-f2c1-447c-85a2-08db6d850c38",
    //     "PrestacionId": "dd8faa50-1bc3-4d1e-ee86-08dbb5e6871f",
    //     "TipoStr": "Formulario Auditoría Prestación Extraordinaria RD 2/2021",
    //     "Tipo": 22021,
    //     "Encabezado": "Encabzado del RD 2/2021",
    //     "ValidacionesObserv": "Observaciones de la validacion",
    //     "DescripcionOperativa": "DescripcionOperativa :  indicaciones del contexto de la auditoria y los pasos para su finalización.",
    //     "ProximasAcciones": "Adjuntar y esperar",
    //     "ResumenEstadoAuditoria": null,
    //     "LOPD": " \r\nSOLIMAT MUTUA COLABORADORA CON LA SEGURIDAD SOCIAL Nº 72 le informa que las direcciones de correo electrónico, así como el resto de los datos de carácter personal incluidos en los mensajes, serán objeto de tratamiento automatizado en nuestros ficheros, con la finalidad de gestionar la agenda de contactos de nuestra entidad, así como para el envío de comunicaciones profesionales por vía electrónica. Vd. podrá en cualquier momento ejercer el derecho de acceso, rectificación, cancelación y oposición en los términos establecidos en la Ley Orgánica 15/1999.\r\n\r\nEl responsable del tratamiento es SOLIMAT MUTUA COLABORADORA CON LA SEGURIDAD SOCIAL Nº 72, C/ Berna, 1- 4ª planta, 45003- Toledo. El contenido de esta comunicación, así como el de toda la documentación anexa, es confidencial y va dirigido únicamente al destinatario del mismo. \r\n\r\nEn el supuesto de que usted no fuera el destinatario, le solicitamos que nos lo indique y no comunique su contenido a terceros, procediendo a su destrucción. Gracias. \r\n\r\nDe acuerdo a lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, usted puede manifestar, en cualquier momento, su deseo de NO recibir puntualmente información por vía electrónica sobre servicios de la mutua que pudieran ser de su interés\r\n\r\nPara ejercitar este derecho, puede dirigirse a nosotros en la siguiente dirección de correo electrónico: LOPD@solimat.com",
    //     "EstadoGeneralRevision": 0,
    //     "Incompatibilidades": [
    //         {
    //             "Id": "20a29e6b-d67a-4698-7cf6-08dcc10aa93c",
    //             "RD_IncompatibilidadId": 2,
    //             "Nombre": "Incompatibilidad 2",
    //             "EstadoRevision": 5,
    //             "FechaFinTramite": null,
    //             "Documentos": [
    //                 {
    //                     "Id": "7e494681-4c1d-4f99-a9fa-08dcc114bb56",
    //                     "RD_DocumentoId": 12,
    //                     "NombrePortal": "Modelo IVA 3T 2020",
    //                     "NombreStorage": "formularios/22021/d231f626-f2c1-447c-85a2-08db6d850c38/dd8faa50-1bc3-4d1e-ee86-08dbb5e6871f/ModeloIVA3T2020",
    //                     "Container": "prestaciones",
    //                     "FechaPresentacion": "2024-09-26T10:22:17.3877156",
    //                     "EstadoDoc": 1,
    //                     "Observaciones": null,
    //                     "Extension": "pdf",
    //                     "Uri": "https://stgmainpv3devm41n.blob.core.windows.net/prestaciones/formularios/22021/d231f626-f2c1-447c-85a2-08db6d850c38/dd8faa50-1bc3-4d1e-ee86-08dbb5e6871f/ModeloIVA3T2020.pdf",
    //                     "SasUri": "https://stgmainpv3devm41n.blob.core.windows.net/prestaciones/formularios/22021/d231f626-f2c1-447c-85a2-08db6d850c38/dd8faa50-1bc3-4d1e-ee86-08dbb5e6871f/ModeloIVA3T2020.pdf?sv=2022-11-02&se=2024-10-07T14%3A18%3A52Z&sr=b&sp=r&sig=UWpObB3pPa2gxCUg5RJ%2F2%2FTp%2BEkYfMHU4pb29Vx%2F9uc%3D",
    //                     "Conflict": false
    //                 }
    //             ]
    //         },
    //         {
    //             "Id": "443662c1-415b-4e96-9835-f46a4594d929",
    //             "RD_IncompatibilidadId": 1,
    //             "Nombre": "Incompatibilidad 1",
    //             "EstadoRevision": 5,
    //             "FechaFinTramite": null,
    //             "Documentos": [
    //                 {
    //                     "Id": "4b9ddedf-2384-46c9-88b8-4ab62372b819",
    //                     "RD_DocumentoId": 15,
    //                     "NombrePortal": "",
    //                     "NombreStorage": "formularios/22021/d231f626-f2c1-447c-85a2-08db6d850c38/dd8faa50-1bc3-4d1e-ee86-08dbb5e6871f/Modelo1602020",
    //                     "Container": "prestaciones",
    //                     "FechaPresentacion": "2024-08-22T12:37:09.030979",
    //                     "EstadoDoc": 4,
    //                     "Observaciones": "Fichero no válido, se ha enviado el del Timestre2",
    //                     "Extension": "pdf",
    //                     "Uri": "https://stgmainpv3devm41n.blob.core.windows.net/prestaciones/formularios/22021/d231f626-f2c1-447c-85a2-08db6d850c38/dd8faa50-1bc3-4d1e-ee86-08dbb5e6871f/Modelo1602020.pdf",
    //                     "SasUri": "https://stgmainpv3devm41n.blob.core.windows.net/prestaciones/formularios/22021/d231f626-f2c1-447c-85a2-08db6d850c38/dd8faa50-1bc3-4d1e-ee86-08dbb5e6871f/Modelo1602020.pdf?sv=2022-11-02&se=2024-10-07T14%3A18%3A52Z&sr=b&sp=r&sig=GGtghb51Zor%2Fs2xYu3YvVKQMlLPJq%2BLQj9Dv0tyjvZM%3D",
    //                     "Conflict": false
    //                 }
    //             ]
    //         }
    //     ]
    // }
    return (
        <>
            <PageMenu title='Real Decreto 2/2021'>
                <div className="btns-header-container">
                    <button onClick={() => history.goBack()}>
                        <img src={IconArrowGray} alt="icon arrow right" />
                    </button>
                </div>
            </PageMenu>
            {loadingGetRoyalDecreeFormById && <Loader activeLoader={true} label="Cargando Formulario"></Loader>}
            <div className='container container-incopatibilidad'>
                <div className="container purpose-header">
                    <p style={{ textAlign: 'justify' }}>{royalDecreeFormById?.Encabezado ? royalDecreeFormById?.Encabezado : noData}</p>
                </div>
                <div className="container container-validations-observations">
                    <h2 className='title-incompatibilidades'>Validaciones y Observaciones</h2>
                    <p>{royalDecreeFormById?.ValidacionesObserv ? royalDecreeFormById?.ValidacionesObserv : noData}</p>
                </div>
                <div className="container container-operational-description">
                    <h2 className='title-incompatibilidades'>Descripción Operativa</h2>
                    <p>{royalDecreeFormById?.DescripcionOperativa ? royalDecreeFormById?.DescripcionOperativa : noData}</p>
                    <ul className='ul-operational-description'>
                        {
                            royalDecreeFormById?.Incompatibilidades.map((inco: any, i: number) => (
                                <li key={i} className='li-operational-description'>{inco.Nombre}</li>

                            ))
                        }
                    </ul>
                </div>
                <div className="container-title-incompatibilidades">
                    <h1 className='title-incompatibilidades'>Incompatibilidades</h1>
                </div>
                {royalDecreeFormById?.Incompatibilidades.map((incopa: any, i: number) => (
                    <div className="container-incopatibilidad" key={incopa.Id}>
                        <fieldset className={`fieldset-container`}>

                            <legend className="col-12">
                                <span className="legend-text">{incopa.Nombre}</span>
                            </legend>
                            <h6 className='incompatibility-status'>{`Estado:  ${checkFormStatusRD(incopa.EstadoRevision)}`}</h6>
                            {incopa?.Documentos && Array.isArray(incopa.Documentos) &&
                                (incopa.Documentos.length === 0 || (incopa.Documentos.length === 1 && Object.keys(incopa.Documentos[0]).length === 0)) ? (
                                <div className="container">
                                    <h3 className='no-documents'>No requiere documentación o ya está solicitada en una Incompatibilidad anterior</h3>
                                </div>
                            ) : null}
                            {incopa.Documentos.some(doc => Object.keys(doc).length > 0) ? incopa?.Documentos.map((doc: any, j: number) => (
                                <div key={doc.Id}>


                                    <div className="container-documentos" >
                                        <div className='col-sm-12 col-md-12 col-lg-3'>
                                            <div className="column-name">Documento solicitado</div>
                                            <div className="name-and-document-container">

                                                <p className='name-doc-incopatibilidad-rd'>{doc.NombrePortal ? `${doc.NombrePortal}` : noData}</p>
                                                {doc.SasUri &&
                                                    <TooltipComponent content="Archivo adjunto">
                                                        <a
                                                            href={doc.SasUri}
                                                            rel={doc.NombrePortal}
                                                            // target="_blank"
                                                            className="link-bold"
                                                        >
                                                            <span className="file-icon-blue"></span>
                                                        </a>
                                                    </TooltipComponent>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-3'>
                                            <div className="column-name">Fecha Presentación</div>
                                            <p className='presentation-date-rd'>{doc.FechaPresentacion ? moment(doc.FechaPresentacion).format('DD-MM-YYYY') : noData}</p>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-4'>
                                            <div className="column-name">Estado Documento</div>
                                            <div className='name-and-icon-information'>
                                                <p>{checkFormStatusDocumentsRD(doc.EstadoDoc)}</p>
                                                {doc.Observaciones &&
                                                    <TooltipComponent content="Observaciones">
                                                        <span className='information-icon-orange' onClick={() => handleShowModal(doc.Observaciones)}></span>
                                                    </TooltipComponent>
                                                }

                                            </div>
                                        </div>


                                        <div className='col-sm-12 col-md-3 col-lg-2'>
                                            <Controller
                                                name={`filesRd-${i}-${j}`}
                                                control={control}
                                                defaultValue={null}
                                                render={({ field }) => (
                                                    <>
                                                        {loadingUpdateIncompatibilityDocuments || !incopa.Nombre ? <label htmlFor={`filesRd-${i}-${j}`} className={`btn-communication btn-load-file-rd btn-disabled `}>
                                                            Adjuntar archivo
                                                            <input
                                                                type="file"
                                                                id={`filesRd-${i}-${j}`}
                                                                className="input-file input-file-rd "
                                                                disabled
                                                            />
                                                        </label> :
                                                            <label htmlFor={`filesRd-${i}-${j}`} className={`btn-communication btn-load-file-rd ${handlePrevetAttach(doc.EstadoDoc) ? 'btn-disabled' : ''} `}>
                                                                Adjuntar archivo
                                                                <input
                                                                    type="file"
                                                                    id={`filesRd-${i}-${j}`}
                                                                    className="input-file input-file-rd "
                                                                    onChange={(e) => {
                                                                        handleFileChange(e, doc.Id, incopa.Id, incopa.Nombre, doc.NombrePortal);
                                                                        field.onChange(e.target.files[0]);
                                                                    }}
                                                                    disabled={handlePrevetAttach(doc.EstadoDoc)}
                                                                    accept="image/*, .pdf, .doc, .docx, zip"
                                                                // multiple
                                                                />

                                                            </label>}
                                                    </>
                                                )}
                                            />

                                        </div>
                                        <span style={{ fontSize: '16px', color: '#CE9238', textAlign: 'center', display: 'block', width:'100%',marginTop:'10px', background:'white',padding:'6px'}}>
                                            Si es necesario adjuntar varios archivos, por favor comprímalos en un archivo ZIP antes de adjuntarlos.
                                        </span>
                                    </div>




                                    {selectedFiles[doc.Id] && (
                                        <div className='container-file-info'>
                                            <p className='selected-file-text-rd'>
                                                {`(${selectedFiles[doc.Id].documentName})`}
                                            </p>
                                            <div className='container-files-list-rd'>
                                                {selectedFiles[doc.Id].files.map((file, index) => (
                                                    <div key={index} className='container-name-file-rd'>
                                                        <p className='name-file-rd'>
                                                            {file.name}
                                                        </p>
                                                        <img
                                                            src={Delete}
                                                            alt="borrar"
                                                            className="remove-file-btn"
                                                            onClick={() => removeFile(doc.Id, index)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )) : ''}
                        </fieldset>
                    </div>
                ))}
                <div className="container container-btn-send-documents-rd">
                    {loadingUpdateIncompatibilityDocuments ? <Button className={`btn-box btn-accent loading btn-otpep active`}><div className="loader-btn"></div>
                    </Button> :
                        <button
                            type="submit"
                            className="btn-box btn-accent"
                            onClick={handleSubmit(onSubmit)}
                            disabled={!hasSelectedFiles}
                        >
                            Enviar Documentos
                        </button>

                    }

                </div>
                <div className="container container-audit-status-summary">
                    <h2 className='title-incompatibilidades'>Resumen Estado Auditoria</h2>
                    <p>{royalDecreeFormById?.ResumenEstadoAuditoria ? royalDecreeFormById?.ResumenEstadoAuditoria : noData}</p>
                </div>
                <div className="container container-next-actions">
                    <h2 className='title-incompatibilidades'>Próximas Acciones</h2>
                    <p>{royalDecreeFormById?.ProximasAcciones ? royalDecreeFormById?.ProximasAcciones : noData}</p>
                </div>
                <div className="container-lopd">
                    <p className='text-lopd'>
                        {royalDecreeFormById?.LOPD}
                    </p>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
                    <span className='information-icon-orange big'></span>
                    <Modal.Title>

                        <h2 className='title-obsevaciones-rd'>Observaciones</h2>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='body-modal-observaciones-rd'>
                    <p className='text-body-modal-observaciones-rd'>{currentObservations ? currentObservations : noData}</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* successfully OR ERROR */}
            <Modal show={showModalSuccessfully} onHide={handleCloseModalsuccessfully} centered>
                <Modal.Header closeButton={false} className='header-modal-observaciones-rd'>
                    {errorUpdateIncompatibilityDocuments ? (
                        <>
                            <img src={IMGnotice} alt="error" />
                            <Modal.Title>
                                <h2 className='title-obsevaciones-rd'>¡ Error al enviar documentos !</h2>
                                <p className='text-obsevaciones-error-rd'>Por favor intentelo de nuevo</p>
                            </Modal.Title>
                        </>
                    ) : (
                        <>
                            <span className='icon-check-blue big'></span>
                            <Modal.Title>
                                <h2 className='title-obsevaciones-rd'>Documentos enviados con éxito</h2>
                            </Modal.Title>
                        </>
                    )}
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalsuccessfully}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default FormRoyalDecree22021;