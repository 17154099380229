import React, { useState } from "react";
import IconDownload from '../../../img/icons/symbols/expediente.png'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  Resize
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

const DocumentsAuthorizationsGrid = ({ documents }) => {
  let tooltip;
  const beforeRender = (args) => {
    const targetColumn = args.target.closest("td").getAttribute('aria-colindex');
    if (targetColumn === '1') {
      tooltip.content = "Descargar";
      tooltip.tipPointerPosition = 'Middle';
      tooltip.position = 'TopLeft';
    } else {
      tooltip.content = args.target.closest("td").innerText;
      tooltip.tipPointerPosition = 'Middle';
      tooltip.position = 'TopLeft';
    }
  };
  const [pageOptions] = useState({
    pageSize: 5,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });
  const [sortingOptions] = useState({
    columns: [{ field: "fecha", direction: "Descending" }]
  });
  const gridTemplate = (props) => {
    return (
      <div className="link-document">
        <a
          href={props.Uri}
          rel={props.Nombre}
          // target="_blank"
          className="link-bold">
          <img style={{ width: '20px', height: '20px' }} src={IconDownload} alt="descargar" />
        </a>
      </div>
    );

  };
  const template = gridTemplate;
  return (
    <div>
      <TooltipComponent
        ref={(t) => (tooltip = t)}
        target=".e-rowcell"
        beforeRender={beforeRender}
      
      >
        <GridComponent
          dataSource={documents}
          allowPaging={true}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
          enableHover={false}
          allowSelection={false}

        >
          <ColumnsDirective>
            <ColumnDirective
              field="uri"
              headerText=""
              template={template}
              width="50"
              textAlign="center"
            />
            <ColumnDirective field="nombre" headerText="Nombre" width="140" />
            <ColumnDirective
              field="fecha"
              headerText="Fecha de subida"
              type="date"
              format="dd/MM/yyyy"
              width="140"
            />

          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </TooltipComponent>
    </div>
  )
};

export default DocumentsAuthorizationsGrid